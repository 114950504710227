<template>
  <a-select show-search v-model="currentValue" :placeholder="placeholder" show-arrow :filter-option="false"
            :loading="loading" :allow-clear="allowClear" :disabled="disabled"
            @search="search" @change="handleChange" :default-active-first-option="false">
    <a-select-option v-for="item in options" :key="item.id" :value="item.id" :title="item.name" :disabled="item.disabled">
      {{ item.name }}
    </a-select-option>
  </a-select>
</template>

<script>
export default {
  name: "dealer-select",
  props: {
    value: {
      required: true
    },
    allowClear: {
      type: Boolean
    },
    placeholder: {
      type: String,
      default: '请选择/输入'
    },
    disabled: {
      type: Boolean
    }
  },
  computed: {
    currentValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  },
  data: () => ({
    loading: false,
    options: []
  }),
  methods: {
    handleChange(val) {
      val || this.search()
      this.$emit('change', val);
    },
    search(keyword) {
      // keyword = (keyword || '').replace(/\[.*\]/g, '')
      // keyword = keyword.replace(/^\s+|\s+$/gm, '')
      keyword = keyword && keyword.trim()
      this.$nextTick(() => {
        (keyword || !this.currentValue) && this.loadData(keyword)
      })
    },
    loadData(keyword) {
      this.options = []
      this.loading = true
      this.$api.BaseDealerService.query({param: { name: keyword}
      }).then(res => {
        this.options = res.data
      }).finally(() => {
        this.loading = false
      })
    }
  },
  created() {
    this.search();
  }
}
</script>
