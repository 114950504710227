<template>
  <div>
    <a-form-model
        ref="searchForm"
        layout='inline'
        :model="searchForm"
        class="search-form"
    >
      <a-form-model-item label="主机厂">
        <manufactor-select ref="manufactorSelect" v-model="searchForm.manufator_id" allow-clear
                           style="width:205px"></manufactor-select>
      </a-form-model-item>
      <a-form-model-item label="经销商">
        <dealer-select ref="dealerSelect" v-model="searchForm.dealer_id" allow-clear
                       style="width:205px"></dealer-select>
      </a-form-model-item>
      <a-form-model-item label="媒体号">
        <a-input v-model.trim="searchForm.code" placeholder="请输入"/>
      </a-form-model-item>
      <a-form-model-item label="昵称">
        <a-input v-model.trim="searchForm.nick_name" placeholder="请输入"/>
      </a-form-model-item>
      <a-form-model-item class="order-madel">
        <div class='flex-wrap'>
          <a-button @click="resetSearch">重置</a-button>
          <a-button style="margin-left: 10px" type="primary" @click="searchClick">搜索
          </a-button>
        </div>
      </a-form-model-item>
    </a-form-model>

    <!-- 表格 -->
    <a-table
        :columns="columns"
        :data-source="liveTableList"
        :loading="tableLoading"
        :pagination="paginations"
        :rowKey="(record, index) => index"
        bordered
        @change="changeTable"
    >
      <div slot='num' slot-scope='text, record, index'>
        {{ parseInt(index) + 1 }}
      </div>
      <div slot="ossUrl" slot-scope='text,record'>
        <a-button type='link' @click="openMaterialDetails(record.code,record.dealer_id)">看素材</a-button>
      </div>
    </a-table>
  </div>
</template>

<script>
import DealerSelect from "@/components/widgets/dealer-select"
import ManufactorSelect from "@/components/widgets/manufactor-select";
import api from "@/api/Edit.js";

const columns = [
  {
    title: "经销商名称",
    dataIndex: "dealer_name",
    width: 110,
  },
  {
    title: "品牌主机厂",
    dataIndex: "manufator_name",
    width: 110,
  },
  {
    title: "媒体号",
    dataIndex: "code",
  },
  {
    title: "昵称",
    dataIndex: "nick_name",
  },
  {
    title: "平台",
    dataIndex: "platform",
  },
  {
    title: "素材量",
    dataIndex: "source_count",
  },
  {
    title: "更新人",
    dataIndex: "modifier",
  },
  {
    title: "更新时间",
    dataIndex: "mtime",
  },
  {
    title: "操作",
    dataIndex: "ossUrl",
    scopedSlots: {customRender: "ossUrl"},
    width: 80,
  },
];

export default {
  name: "cliptTableList",
  data() {
    return {
      searchForm: {
        code: '',
        manufator_id: '',
        dealer_id: '',
        nick_name: '',
      },
      columns,
      liveTableList: [],
      tableLoading: false,
      layout: {
        labelCol: {span: 4},
        wrapperCol: {span: 17},
      },
      paginations: {
        current: 1,
        pageSize: 10,
        total: 0,
        showTotal: total => `总共 ${total} 条数据`,
      },
    }
  },
  components: {
    ManufactorSelect,
    DealerSelect,
  },
  mounted() {
    this.getPayLoad()
  },
  methods: {
    getPayLoad(current) {
      let payload = {
        page_no: current,
        page_size: 10,
        nick_name: this.searchForm.nick_name || "",
        code: this.searchForm.code || "",
        manufator_id: this.searchForm.manufator_id || "",
        dealer_id: this.searchForm.dealer_id || "",
      };
      this.tableLoading = true;
      api.getCliptTableList(payload).then((res) => {
        this.tableLoading = false;
        if (res.code == 200) {
          this.liveTableList = res.data.list || []
          this.paginations.total = parseInt(res.data.total)
          this.paginations.current = res.data.page_num
        }
      });
    },
    searchClick() {
      this.getPayLoad(1);
    },
    resetSearch() {
      this.searchForm = {
        code: '',
        manufator_id: '',
        dealer_id: '',
        nick_name: '',
      }
      this.getPayLoad(1);
    },
    changeTable(pagination) {
      this.getPayLoad(pagination.current);
    },
    openMaterialDetails(code,dealer_id) {
      console.log(code, dealer_id);
      this.$router.push({
        name: 'cloudClip:materialDetails',
        query: {
          code,
          dealer_id
        }
      })
    }
  }
}
</script>

<style scoped>
.order-madel {
  display: flex;
  justify-content: flex-end;
}
.flex-wrap {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding:30px 30px 30px 0px;
}
</style>
